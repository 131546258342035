
import axios from '@/axios';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { logOutOutline, ellipsisHorizontal, trashBinOutline } from 'ionicons/icons';

import {
  actionSheetController,
  IonPage,
  IonIcon,
  IonLabel,
  IonButton,
  IonItem,
  IonList,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  alertController,
  toastController
} from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'Profile',
  components: {
    IonPage,
    IonIcon,
    IonLabel,
    IonButton,
    IonItem,
    IonList,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent
  },
  setup(): any {
    const store = useStore();
    const router = useRouter();

    store.dispatch('user/getDataAPI');

    const meta = computed(() => store.getters['user/meta']);
    const children = computed(() => store.getters['user/children']);

    const presentActionSheet = async function() {
      const actionSheet = await actionSheetController.create({
        header: 'Account options',
        buttons: [
          {
            cssClass: 'text-tertiary',
            icon: trashBinOutline,
            text: 'Delete account',
            role: 'destructive',
            handler: () => {
              presentDeleteAlert();
            }
          },
          {
            cssClass: 'text-primary',
            icon: logOutOutline,
            text: 'Logout',
            handler: () => {
              router.replace('/log-out');
            }
          },
          {
            cssClass: 'text-white',
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      });

      await actionSheet.present();

      // const { role } = await actionSheet.onDidDismiss();
      // console.log('onDidDismiss resolved with role', role);
    };

    const presentDeleteAlert = async function() {
      const alert = await alertController.create({
        cssClass: 'my-custom-class',
        header: 'Delete account',
        // subHeader: child.initials,
        message: `All your data and child's data will be <em>permanently</em> deleted.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: 'Delete account',
            role: 'confirm'
          }
        ]
      });

      await alert.present();

      const { role } = await alert.onDidDismiss();

      if (role !== 'cancel') {
        deleteAccount();
      }
    };

    async function deleteSuccessful() {
      await toastController.create({
        color: 'success',
        message: 'Account has been deleted successfully',
        duration: 5000,
        buttons: [
          {
            text: 'x',
            role: 'cancel'
          }
        ]
      });

      // log them out
      store.dispatch('auth/logout');
      router.replace('/bye');
    }

    async function submitFailed(err) {
      const message = err?.response?.data?.message || 'Error saving your changes.';

      const toast = await toastController.create({
        color: 'danger',
        message: message,
        duration: 5000
      });

      return toast.present();
    }

    const deleteAccount = async function() {
      const url = `wp-json/user/v1/me/`;
      await axios
        .delete(url)
        .then(deleteSuccessful)
        .catch(submitFailed);
    };

    return {
      chevronBackOutline,
      router,
      meta,
      ellipsisHorizontal,
      logOutOutline,
      trashBinOutline,
      children,
      presentActionSheet,
      presentDeleteAlert
    };
  }
};
